import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';
import SEO from '../SEO/SEO';
import NavBar from '../NavBar/NavBar';
import Detail from '../Detail/Detail';
import Carousel from '../Carousel/Carousel';

const VideoContainer = ({ post, posts, blocked }) => {
    /* Don't allow to see the page if the user is blocked */
    if (blocked) {
        navigate('/');
        return null;
    }
    const { metadata_description, metadata_title, metadata_keywords, metadata_image, publish_date } = post.data;

    useEffect(() => {
        const html = document.querySelector('html');
        html.style.backgroundColor = '#79c2f0';
    }, []);
    return (
        <>
            <SEO
                description={metadata_description}
                title={metadata_title}
                keywords={metadata_keywords}
                image={metadata_image}
                type="Article"
                publishDate={post.first_publication_date}
                articleSection="Videos"
                urlPath ={post.url}
            />
            <NavBar active="videos" extraClass="video-navbar" logoNavBar={true} />
            <Detail post={post.data} extraClass="video-detail" isVideo={true} />
            <Carousel postId={post.id} posts={posts} className="video-slider" addIcon={true} />
        </>
    );
};

VideoContainer.propTypes = {
    post: PropTypes.object,
    posts: PropTypes.array,
    blocked: PropTypes.bool,
};

const mapStateToProps = ({ blocked }) => {
    return { blocked };
};

export default connect(mapStateToProps, null)(VideoContainer);
