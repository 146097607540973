import * as React from 'react';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { getSafe } from '../utils/getSafe';
import Loading from '../components/Loading/Loading';
import VideoContainer from '../components/VideoDetail/VideoContainer';

export const query = graphql`
    query BlogVideoQuery($id: String) {
        prismicVideos(id: { eq: $id }) {
            id
            first_publication_date
            url
            data {
                edge_id {
                    text
                }
                publish_date(formatString: "DD-MM-YYYY")
                description {
                    text
                }
                image {
                    alt
                    localFile {
                        childImageSharp {
                            gatsbyImageData(width: 1200)
                        }
                    }
                }
                title {
                    text
                }
                metadata_description {
                    text
                }
                metadata_title {
                    text
                }
                metadata_keywords {
                    text
                }
                metadata_image {
                    url
                }
            }
        }
        allPrismicVideos(sort: { fields: [data___publish_date], order: DESC }) {
            nodes {
                id
                url
                data {
                    description {
                        text
                    }
                    image {
                        alt
                        localFile {
                            childImageSharp {
                                gatsbyImageData(width: 1200)
                            }
                        }
                    }
                    title {
                        text
                    }
                }
            }
        }
    }
`;

const Video = ({ data }) => {
    if (!data) return null;
    const post = getSafe(() => data.prismicVideos, {});
    const posts = getSafe(() => data.allPrismicVideos.nodes, {});
    return (
        <Layout className="video-page">
            <Loading />
            <VideoContainer post={post} posts={posts} />
        </Layout>
    );
};
export default Video;
